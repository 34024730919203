<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <SearchForm
          ref="SearchForm"
          :address_list="address_list"
          :shipper_list="shipper_list"
          :vehicle_list="vehicle_list"
          :goods_list="goods_list"
          :cargoowner_list="cargoowner_list"
          :customer_list="customer_list"
          :orderStatus="false"
          :show-create-time="false"
          @handleSubmit="handleSeachSubmit"
      />
    </div>
    <s-table
        v-if="showTabel"
        ref="table"
        rowKey="order_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        tableLayout="auto"
        :scroll="{x:true}"
    >
      <div slot="zh_loadunload_list" slot-scope="text, item">
        <div v-for="item in item.loadunload_list">{{ item.zhdz_info ? item.zhdz_info.address_name : '' }}</div>
      </div>
      <div slot="xh_loadunload_list" slot-scope="text, item">
        <div v-for="item in item.loadunload_list">{{ item.xhdz_info ? item.xhdz_info.address_name : '' }}</div>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="orange" v-if="text===0">待完善</a-tag>
        <a-tag color="green" v-if="text===1">进行中</a-tag>
        <a-tag color="blue" v-if="text===2">已完成</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:orderVehicle @click="handleOrderVehicle(item)">查看调度车辆</a>
        <!--        <a v-action:edit @click="handleEdit(item)">查看订单</a>-->
        <a v-action:details @click="handleDetails(item)">查看订单详情</a>
        <a v-action:delete @click="handleDelete(item)">删除</a>
      </div>
    </s-table>
    <ShowOrderVehicle
        ref="ShowOrderVehicle"
        @handleSubmit="handleRefresh"
    />
    <DetailsModel ref="DetailsModel" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/order'
import {STable} from '@/components'
import SaveForm from '../SaveForm.vue'
import SearchForm from '../SearchForm.vue'
import * as addressApi from "@/api/data/address";
import * as shipperApi from "@/api/data/shipper";
import * as vehicleApi from "@/api/vehicle";
import * as goodsApi from "@/api/data/goodsName";
import * as cargoOwnerApi from "@/api/data/cargoOwner";
import * as customerApi from "@/api/data/customer";

import ShowOrderVehicle from "@/views/order/model/orderVehicle/Index.vue";
import DetailsModel from "../DetailsModel.vue";
import {isEmpty} from "@/utils/util";

export default {
  name: 'orderCompleted',
  components: {
    ShowOrderVehicle,
    STable,
    SaveForm,
    SearchForm,
    DetailsModel
  },
  data() {
    return {
      // 查询参数
      queryParam: {
        status: 2,
        create_time: []
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: '记录ID',
        //   dataIndex: 'order_id'
        // },
        {
          title: '订单状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '所属客户',
          dataIndex: 'customer.customer_name',
        },
        {
          title: '装货地址',
          dataIndex: 'zloadunload_list',
          scopedSlots: {customRender: 'zh_loadunload_list'}
        },
        {
          title: '卸货地址',
          dataIndex: 'xloadunload_list',
          scopedSlots: {customRender: 'xh_loadunload_list'}
        },
        {
          title: '派单时间',
          dataIndex: 'create_time'
        },
        {
          // fixed:'right',
          title: '操作',
          dataIndex: 'action',
          // width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],

      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      // 地址列表
      address_list: [],
      // 托运人列表
      shipper_list: [],
      // 车辆列表
      vehicle_list: [],
      // 品名列表
      goods_list: [],
      // 货主列表
      cargoowner_list: [],
      // 客户列表
      customer_list: [],

      showTabel: false
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$emit('ok')
  },
  methods: {
    setMonth(month) {
      this.$nextTick(() => {
        this.showTabel = true
        this.queryParam.create_time = month
        this.handleRefresh(true)
      })
    },
    /**
     * 获取所需数据
     */
    getDataList() {
      // 地址信息
      addressApi.getSelectList().then(({data: {list}}) => {
        this.address_list = list
      })
      // 托运人信息
      shipperApi.getSelectList().then(({data: {list}}) => {
        this.shipper_list = list
      })
      // 车辆信息
      vehicleApi.getSelectList().then(({data: {list}}) => {
        this.vehicle_list = list
      })
      // 商品信息
      goodsApi.getSelectList().then(({data: {list}}) => {
        this.goods_list = list
      })
      // 货主信息
      cargoOwnerApi.getSelectList().then(({data: {list}}) => {
        this.cargoowner_list = list
      })
      // 客户信息
      customerApi.getSelectList().then(({data: {list}}) => {
        this.customer_list = list
      })
      // 司机与押运员
      // personnelApi.all().then(({data: {driver_list, supercargo_list}}) => {
      //   this.driver_list = driver_list
      //   this.supercargo_list = supercargo_list
      // })

    },

    /**
     * 查看已调度车辆
     */
    handleOrderVehicle({order_id}) {
      this.$refs.ShowOrderVehicle.add(order_id, true)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 查看订单详情
     */
    handleDetails(item) {
      this.$refs.DetailsModel.open(item)
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({orderId: item['order_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      if (!isEmpty(this.$refs.table)) {
        this.$refs.table.refresh(bool)
      }
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
