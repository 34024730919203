<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      @ok="handleCancel"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div>
        <div class="item cert_cover">
          <div><b>基本信息：</b></div>
          <a-row>
            <a-col :span="8">托运人：<a>{{ record.shipper ? record.shipper.shipper_name : '' }}</a></a-col>
            <a-col :span="8">所属客户：<a>{{ record.customer ? record.customer.customer_name : '' }}</a></a-col>
            <a-col :span="8">计划备注：
              <a>{{ record.plan_remarks }}</a>
              <a target="_blank"
                 v-for="item in record.plan_remarks_file_list"
                 :href="item.external_url">
                <img
                    class="image"
                    :src="item.external_url"
                />
              </a>
            </a-col>
            <a-col :span="8">状态：
              <a-tag color="orange" v-if="record.status===0">待完善</a-tag>
              <a-tag color="green" v-if="record.status===1">进行中</a-tag>
              <a-tag color="blue" v-if="record.status===2">已完成</a-tag>
            </a-col>
          </a-row>
          <div></div>
        </div>
        <div class="item">
          <div><b>装卸信息：</b></div>
          <a-row v-for="item in record.loadunload_list">
            <a-col :span="12">
              <div>装货单号：<a>{{ item.zhdh }}</a></div>
              <div>装货单号：<a>{{ item.zhdz_info ? item.zhdz_info.address_name : '' }}</a></div>
              <div>装货品名：<a>{{ item.zhpm_info ? item.zhpm_info.goods_name : '' }}</a></div>
              <div>装货数量：<a>{{ item.zhsl }}</a></div>
              <div>装货罐号：<a>{{ item.zhgh }}</a></div>
              <div>装货货主：<a>{{ item.zhhz_info ? item.zhhz_info.cargoowner_name : '' }}</a></div>
              <div>装货联系方式：<a>{{ item.zhlxfs }}</a></div>
            </a-col>
            <a-col :span="12">

              <div>装货单号：<a>{{ item.xhdh }}</a></div>
              <div>装货单号：<a>{{ item.xhdz_info ? item.xhdz_info.address_name : '' }}</a></div>
              <div>装货品名：<a>{{ item.xhpm_info ? item.xhpm_info.goods_name : '' }}</a></div>
              <div>装货数量：<a>{{ item.xhsl }}</a></div>
              <div>装货罐号：<a>{{ item.xhgh }}</a></div>
              <div>装货货主：<a>{{ item.xhhz_info ? item.xhhz_info.cargoowner_name : '' }}</a></div>
            </a-col>

          </a-row>
          <div></div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>

import {UploadFile, UploadImage} from '@/components'

export default {
  components: {
    UploadFile,
    UploadImage
  },
  data() {
    return {
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前记录
      record: {},

      statusArray: ['', '已入职']
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    open(item) {
      console.log(item)
      this.record = item
      this.title = '查看详情'
      this.visible = true
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">
.item {
  padding: 10px;
  border: 1px #e8e8e8 solid;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.cert_cover {
  .image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px #e8e8e8 solid;

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
